import React, { memo, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import { RefState } from 'hooks/useRefState';
import { AutoplayOptions } from 'components/CamPlayerV2/constants';
import RewindButton from './icons/Rewind';
import FastForwardButton from './icons/FastForward';
import PauseButton from './icons/Pause';
import PlayButton from './icons/Play';
import styles from './CamPlayerOverlayControls.module.scss';

export interface CamPlayerOverlayControlsProps {
  playing: boolean;
  pause: () => void;
  play: () => void;
  setPlayerTime?: (utcTimeToPlay: number, wantsRewindPlaying: boolean) => void;
  requestFullscreen?: () => void;
  className?: string;
  currentTimestampRef?: RefState<Date | undefined>;
  hasCurrentTimestamp: boolean;
  playerRef: HTMLElement | null;
  userWantsRewindPlaying: boolean;
  controlsVisible?: boolean;
  autoplay?: AutoplayOptions;
}

const CamPlayerOverlayControls: React.FC<CamPlayerOverlayControlsProps> = ({
  playing,
  className,
  pause,
  play,
  setPlayerTime,
  userWantsRewindPlaying,
  requestFullscreen,
  currentTimestampRef,
  hasCurrentTimestamp,
  playerRef,
  controlsVisible,
  autoplay = AutoplayOptions.AUTOPLAY_ENABLED,
}) => {
  const SKIP_TIME = 5000;
  const hasUsedControls = React.useRef<boolean>(false);

  const togglePlayPause = useCallback(
    (event) => {
      event.stopPropagation();
      if (playing) {
        pause();
      } else {
        play();
      }

      if (!hasUsedControls.current) {
        hasUsedControls.current = true;
      }
    },
    [playing, pause, play],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        event.preventDefault();
        togglePlayPause(event);
      }
      const currentTimestamp = currentTimestampRef?.current?.value;
      if (event.code === 'ArrowLeft') {
        if (currentTimestamp && setPlayerTime) {
          setPlayerTime(currentTimestamp.getTime() - SKIP_TIME, userWantsRewindPlaying);
        }
      }
      if (event.code === 'ArrowRight') {
        if (currentTimestamp && setPlayerTime) {
          setPlayerTime(currentTimestamp.getTime() + SKIP_TIME, userWantsRewindPlaying);
        }
      }
      if (event.code === 'KeyF' && requestFullscreen) {
        requestFullscreen();
      }
    },
    [
      currentTimestampRef,
      requestFullscreen,
      setPlayerTime,
      togglePlayPause,
      userWantsRewindPlaying,
    ],
  );

  useEffect(() => {
    if (playerRef) {
      playerRef.addEventListener('keydown', handleKeyDown);
      return () => {
        playerRef.removeEventListener('keydown', handleKeyDown);
      };
    }
    return undefined;
  }, [handleKeyDown, playerRef]);

  const skipBackHandler = useCallback(
    (event) => {
      event.stopPropagation();
      if (currentTimestampRef?.current.value && setPlayerTime) {
        setPlayerTime(
          currentTimestampRef.current.value.getTime() - SKIP_TIME,
          userWantsRewindPlaying,
        );
      }
    },
    [currentTimestampRef, setPlayerTime, userWantsRewindPlaying],
  );

  const skipForwardHandler = useCallback(
    (event) => {
      event.stopPropagation();
      if (currentTimestampRef?.current.value && setPlayerTime) {
        setPlayerTime(
          currentTimestampRef.current.value.getTime() + SKIP_TIME,
          userWantsRewindPlaying,
        );
      }
    },
    [currentTimestampRef, setPlayerTime, userWantsRewindPlaying],
  );

  return (
    <div
      className={classNames({
        [styles.overlayWrapper]: true,
        [styles.show]:
          (!hasUsedControls.current && autoplay === AutoplayOptions.AUTOPLAY_DISABLED) ||
          !!controlsVisible,
        className,
      })}
      data-testid="cam-player-overlay-controls"
    >
      <div className={styles.overlayContainer}>
        {hasCurrentTimestamp && setPlayerTime && (
          <Button
            data-testid="cam-player-rewind-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={styles.skipButton}
            onClick={skipBackHandler}
          >
            <RewindButton />
            <span>{SKIP_TIME / 1000}</span>
          </Button>
        )}
        {playing && (
          <Button
            data-testid="cam-player-pause-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={styles.playPauseButton}
            onClick={togglePlayPause}
          >
            <PauseButton />
          </Button>
        )}
        {!playing && (
          <Button
            data-testid="cam-player-play-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={styles.playPauseButton}
            onClick={togglePlayPause}
          >
            <PlayButton />
          </Button>
        )}
        {hasCurrentTimestamp && setPlayerTime && (
          <Button
            data-testid="cam-player-fast-forward-button"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={styles.skipButton}
            onClick={skipForwardHandler}
          >
            <FastForwardButton />
            <span>{SKIP_TIME / 1000}</span>
          </Button>
        )}
        <button
          data-testid="cam-player-play-pause-toggle"
          onClick={togglePlayPause}
          type="button"
          aria-label="Play and Pause"
          className={styles.playPauseButtonBG}
        />
      </div>
    </div>
  );
};

export default memo(CamPlayerOverlayControls);
