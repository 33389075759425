import { RewindStitcherVideoJsPlayer } from 'components/RewindCamPlayer/plugins/rewindStitcher/src/rewindStitcher.types';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { VideoJsPlayer } from 'video.js';

const TIMEOUT = 5000;
const useCamControls = (
  playerEventTriggerEl: HTMLDivElement | null,
  camPlayer: RewindStitcherVideoJsPlayer | VideoJsPlayer | null,
  isThumbnail: boolean = false,
) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [controlsVisibility, setControlsVisibility] = useState({
    staticControlsVisible: true,
    primaryControlsVisible: true,
  });

  const isTouchDevice = useIsTouchDevice();

  const setShowControlsTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleContextMenu = useCallback((event) => {
    // Disable the context menu so the user can't enable the native HTML5 video controls
    event.preventDefault();
    return false;
  }, []);

  const clearControls = useCallback(() => {
    setControlsVisibility({
      staticControlsVisible: false,
      primaryControlsVisible: false,
    });
  }, [setControlsVisibility]);

  const resetAndHideControlsOnTimeout = useCallback(() => {
    if (setShowControlsTimeout?.current) {
      clearTimeout(setShowControlsTimeout.current);
    }

    setShowControlsTimeout.current = setTimeout(clearControls, TIMEOUT);
  }, [clearControls]);

  const handleUserActive = useMemo(
    () =>
      throttle(
        () => {
          setControlsVisibility({
            staticControlsVisible: true,
            primaryControlsVisible: true,
          });

          resetAndHideControlsOnTimeout();
        },
        500,
        { leading: true, trailing: false },
      ),
    [setControlsVisibility],
  );

  const handleUserInactive = useCallback(() => {
    setControlsVisibility({
      staticControlsVisible: true,
      primaryControlsVisible: false,
    });

    resetAndHideControlsOnTimeout();
  }, [clearControls]);

  const handleTouchToggle = useCallback(
    (event) => {
      if (event.target.parentElement !== camPlayer?.el()) {
        resetAndHideControlsOnTimeout();
        return;
      }

      setControlsVisibility((stateControlsVisibility) => {
        resetAndHideControlsOnTimeout();

        return {
          staticControlsVisible: !stateControlsVisibility.staticControlsVisible,
          primaryControlsVisible: !stateControlsVisibility.primaryControlsVisible,
        };
      });
    },
    [setControlsVisibility, resetAndHideControlsOnTimeout, camPlayer],
  );

  const handleSrcChange = useCallback(() => {
    setControlsVisibility((stateControlsVisibility) => ({
      staticControlsVisible: true,
      primaryControlsVisible: stateControlsVisibility.primaryControlsVisible,
    }));

    resetAndHideControlsOnTimeout();
  }, []);

  useEffect(() => {
    if (camPlayer && playerEventTriggerEl) {
      if (!isThumbnail) {
        if (initialLoad) {
          resetAndHideControlsOnTimeout();
          setInitialLoad(false);
        }
      }
    }
  }, [camPlayer, playerEventTriggerEl, isThumbnail, initialLoad, setInitialLoad]);

  useEffect(() => {
    if (camPlayer && playerEventTriggerEl) {
      if (!isThumbnail) {
        camPlayer?.on('contextmenu', handleContextMenu);
        camPlayer?.on('sourceset', handleSrcChange);

        if (!isTouchDevice) {
          playerEventTriggerEl.addEventListener('mousemove', handleUserActive);
          playerEventTriggerEl.addEventListener('mouseleave', handleUserInactive);
        } else {
          playerEventTriggerEl.addEventListener('touchstart', handleTouchToggle);
        }

        camPlayer.one('dispose', () => {
          camPlayer.off('contextmenu', handleContextMenu);
          camPlayer?.off('sourceset', handleSrcChange);

          playerEventTriggerEl.removeEventListener('mousemove', handleUserActive);
          playerEventTriggerEl.removeEventListener('mouseleave', handleUserInactive);
          playerEventTriggerEl.removeEventListener('touchstart', handleTouchToggle);

          if (setShowControlsTimeout.current) {
            clearTimeout(setShowControlsTimeout.current);
          }
        });
      } else {
        camPlayer.controls(false);
      }
    }
  }, [
    resetAndHideControlsOnTimeout,
    setInitialLoad,
    playerEventTriggerEl,
    camPlayer,
    isThumbnail,
    handleUserActive,
    handleUserInactive,
    handleContextMenu,
  ]);

  return {
    controlsVisibility,
  };
};

export default useCamControls;
