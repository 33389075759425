import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';

import styles from './CamPlaceholder.module.scss';

const CamPlaceholder = ({ alias, isDown, shouldLoad }) => (
  <div
    className={styles.CamPlaceholder}
    style={
      shouldLoad
        ? {
            backgroundImage: `url(${config.camStillURL}/${alias}/latest_full.jpg)`,
          }
        : {}
    }
  >
    {!isDown && (
      <div className={styles.spinnerContainer}>
        <div className={styles.loading} />
      </div>
    )}
  </div>
);

CamPlaceholder.propTypes = {
  alias: PropTypes.string,
  shouldLoad: PropTypes.bool,
};

CamPlaceholder.defaultProps = {
  alias: '',
  shouldLoad: false,
};

export default CamPlaceholder;
